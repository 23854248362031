import React from 'react';
import { Heading } from '../../components';
import { richText } from '../../util/richText';

import css from './ListingPage.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 20;

const SectionTextMaybe = props => {
  const { text, heading, showAsIngress = false } = props;
  const textClass = showAsIngress ? css.ingress : css.text;
  const content = richText(text, {
    longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
    longWordClass: css.longWord,
    breakChars: '/',
  });
  const shortDescription="Duration of the service (in minutes)(in french)"
  const information="Additional Information(in french)"
  const cancellation="Cancellation policy(in french)"
  const shortDescription1="Durée de la prestation (en minutes)(en anglais)"
  const information1="Informations supplémentaires(en anglais)"
  const cancellation1="Politique d'annulation(en anglais)"

  const show=heading===shortDescription||heading===information||heading===cancellation||heading===shortDescription1||heading===information1||heading===cancellation1?true:false
  

if(show){
  return null
}

  return text ? (
    <div className={css.sectionText}>
      {heading ? (
        <Heading as="h2" rootClassName={css.sectionHeading}>
          {heading}
        </Heading>
      ) : null}
      <p className={textClass}>{content}</p>
    </div>
  ) : null;
};

export default SectionTextMaybe;
